import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { SKIP_ERROR_CODES } from './skip-error-codes.token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageService: MessageService,
        private router: Router
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.includes(environment.api_url)) {
            // IE used to cache the responses - bugfix
            let headers = request.headers.append('Cache-control', 'no-cache');
            headers = headers.append('Cache-control', 'no-store');
            headers = headers.append('Expires', '0');
            headers = headers.append('Pragma', 'no-cache');

            request = request.clone({
                headers: headers,
            });
        }

        return next.handle(request).pipe(
            catchError((error) => {
                const skipCodes = request.context.get(SKIP_ERROR_CODES);

                // If we have skipCodes, and the current error status is in the list, skip the error logic
                if (skipCodes && skipCodes.includes(error.status)) {
                    // Just rethrow the error (or handle it locally if you want)
                    return throwError(() => error);
                }

                if (
                    error.status === 0 &&
                    error.message.includes('Http failure response for')
                ) {
                    this.messageService.add({
                        text: 'myGadget requires VPN access. Please connect to the GSK Network and try again.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                    this.router.navigate(['/error']);
                } else if (error.status === 503) {
                    this.messageService.add({
                        text: 'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. Please try again later.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                    this.router.navigate(['/error']);
                } else {
                    this.messageService.add({
                        text: 'myGadget was not able to complete your request. Please try again later. If you are still not able to complete your myGadget request, contact the Helpdesk.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                    return throwError(() => error);
                }
            })
        );
    }
}
