import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { UserFullData } from '../models/userFullData';
import { DirectReport } from '../models/directReport';
import { UserBase } from '../models/userBase';
import { TrainingBase } from '../models/trainingBase';
import { UserViewData } from '../models/userViewData';
import { SKIP_ERROR_CODES } from '../utils/skip-error-codes.token';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private readonly http: HttpClient,
        private readonly loadingService: LoadingService
    ) {}

    getUser(mudid: string): Observable<UserFullData> {
        this.loadingService.show();

        const endpoint = `/mygadget/user/${mudid}`;
        return this.http
            .get<UserFullData>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getUserViewData(mudId: string): Observable<UserViewData> {
        this.loadingService.show();

        const endpoint = `/mygadget/userViewData/${mudId}`;
        return this.http
            .get<UserFullData>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getUsers(mudIds: string[]): Observable<UserBase[]>;
    getUsers(keyword: string): Observable<UserBase[]>;
    getUsers(arg: any): Observable<UserBase[]> {
        if (typeof arg === 'string') {
            const endpoint = `/users/searchBasic`;
            return this.http.get<UserBase[]>(endpoint, {
                headers: new HttpHeaders({ searchQuery: encodeURI(arg) }),
            });
        } else {
            const endpoint = '/userBase';
            const mudIds = JSON.parse(JSON.stringify(arg)) as string[];
            const observableArray: Array<Observable<UserBase[]>> = [];
            for (let i = 0; mudIds.length > 0; i++) {
                const mudIdsForQuery = mudIds.splice(0, 100);
                let params = new HttpParams();
                mudIdsForQuery.forEach((mudId) => {
                    params = params.append('mudId', mudId);
                });
                observableArray[i] = this.http
                    .get<UserBase[]>(endpoint, {
                        params: params,
                        context: new HttpContext().set(SKIP_ERROR_CODES, [404]),
                    })
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 404) {
                                return of<UserBase[]>([]);
                            }
                            return throwError(() => error);
                        })
                    );
            }

            this.loadingService.show();
            return forkJoin(observableArray).pipe(
                map((userBases) => {
                    const result: UserBase[] = [];
                    userBases.forEach((u) => result.push(...u));
                    return result;
                }),
                finalize(() => this.loadingService.hide())
            );
        }
    }

    updateUser(user: {
        mudId: string;
        displayName: string;
        onLongTermLeave: boolean;
    }) {
        this.loadingService.show();

        const endpoint = `/mygadget/user`;
        return this.http
            .post(endpoint, user)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getDirectReports(managerMudid?: string): Observable<DirectReport[]> {
        this.loadingService.show();

        const endpoint = managerMudid
            ? `/mygadget/managerProxy/${managerMudid}/directReports`
            : '/mygadget/user/directReports';
        return this.http.get<DirectReport[]>(endpoint).pipe(
            map((directReports) =>
                directReports.map((report) => new DirectReport(report))
            ),
            finalize(() => this.loadingService.hide())
        );
    }

    checkDueDatesForDirectReports(
        reports: DirectReport[]
    ): Observable<DirectReport[]> {
        const endpoint = `/mygadget/user/directReports/checkDueDates`;
        return this.http
            .post<DirectReport[]>(endpoint, reports)
            .pipe(
                map((directReports) =>
                    directReports.map((report) => new DirectReport(report))
                )
            );
    }

    setUsersLongTermLeave(
        mudid: string,
        onLeave: boolean,
        trainings: TrainingBase[] = [],
        isAdminRequest?: boolean
    ) {
        this.loadingService.show();

        const endpoint = isAdminRequest
            ? `/mygadget/managerProxy/user/${mudid}/setUsersLongTermLeave?onLeave=${onLeave}`
            : `/mygadget/user/${mudid}/setUsersLongTermLeave?onLeave=${onLeave}`;
        return this.http
            .post<TrainingBase[]>(endpoint, trainings)
            .pipe(finalize(() => this.loadingService.hide()));
    }
}
